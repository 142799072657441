.content{
    height: auto;
    width: 100%;
    margin-top: 40px;
}

.content__subTitleContainer{
    height: auto;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
}

.content__imgContainer{
    height: 200px;
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    overflow: hidden;
    object-fit: cover;
}

#imgArticle{
    height: 100%;
    width: 100%;
}

.content__description{
    height: auto;
    width: 600px;
    text-align: left;
}

@media (max-width: 768px) {
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content__subTitleContainer{
        text-align: left;
    }

    .content__description{
        height: auto;
        width: 100%;
        text-align: left;
    }
}

@media (max-width: 420px) {
    .content__imgContainer{
        height: 200px;
        width: 100%;
    }
}