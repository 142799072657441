@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");

:root {

    --beige: #4f4c48;
    --beige-C: #918d88;
    --noir: #000;
    --grisC: #3d424a;
    --azur: #1c232b;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}
p {
    font-family: 'Poppins', sans-serif;
}
body {
    font-family: 'DM Serif Display', serif;
    overflow-x: hidden;
}