// HomeSection.scss
.home-section {
    height: 180px;
    background-image: url('../assets/background/image-de-fond-de-homePage-belle-ville-voyage-camping-luxembourg-visiter.webp');
    background-size: cover;
    background-position: center;
    position: relative;
    @media screen and (max-width: 621px) {
      height: 280px;
    }
    
    .overlay {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: rgb(255, 255, 255);
      background: rgba(102, 102, 102, 0.267); // Effet blanchi
      
      h1 {
        font-size: 1.5rem;
        font-weight: bold;
      }
      
      p {
        font-size: 0.9rem;
        max-width: 80%;
        margin: 0 auto;
      }
  
      .instagram-button {
        margin-top: 10px;
        background: transparent;
        border: none;
        color: white;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
    
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
    }
  }
  