.containerShoppingCart_item{
    margin-top: 10px;
    height: 96px;
    display: flex;
    align-items: center;
    @media (max-width:380px) {
        height: 93px;
    }
    &_bouble{
        height: 100%;
        width: 100%;
        background-color: #f8f8f8;
        border: 1px solid rgb(218, 218, 218);
        border-radius: 10px;
        display: flex;
        align-items: center ;
        &_imgContainer{
            width: 110px;
            height: calc(100% - 10px);
            margin-left: 6px;
            &_img{
                height: 100%;
                width: 100%;
                border-radius: 5px;
            }
        }
        &_content{
            margin-left: 6px;
            height: calc(100% - 10px);
            width: calc(100% - 110px);
            margin-right: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &_title{
                height: auto;
                width: 100%;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                line-clamp: 1;
                -webkit-line-clamp: 1;
                overflow: hidden;
            }
            &_text{
                font-size: 13px;
                margin: 0px;
                padding: 0px;

                &_activity{
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 7px;
                    @media (max-width:380px) {
                        flex-direction: column;
                        width: fit-content;
                    }
                }
            }
        }
    }
    &_deleteBtn{
        height: 40px;
        width: 40px;
        margin-left: 10px;
        margin-right: 10px;
        background-color: #f8f8f8;;
        border: 1px solid rgb(218, 218, 218);;
        border-radius: 5px;
        cursor: pointer;
        &_svgIcon{
            height: 100%;
            width: 100%;
        }
    }
}