.containerButtonBlack{
    background-color: rgb(0, 0, 0);
    color: White;
    width: 140px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s;
    font-size: 0.8rem;
    text-decoration: none;
    text-align: center;
    font-family: "Poppins", sans-serif;
}

.containerButtonBlack:hover{
    background-color: White;
    color: black;
    border: 1px solid ;
}

.containerButtonWhite{
    background-color: White;
    color: black;
    width: 140px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s;
    font-weight: bold;
    font-size: 0.8rem;
    text-decoration: none;
    text-align: center;
    font-family: "DM Serif Display", sans-serif;
}

.containerButtonWhite:hover{
    background-color: black;
    color: White;
}

/*
---------------------------------
---------------------------------
AVEC BORDURE / pas encore fini il faut ajouter les 
div avec le même nom " containerButtonBlack_WB "
et faire un if else et comme prop un true false ..
---------------------------------
---------------------------------
*/

