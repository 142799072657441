
/*--------------------------
                CONTAINER 2
    -----------------------------*/


    .container2 {
        height: auto;
    }
    
    
    .container2-1 {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .bloc2-1 {
        width: 100%;
        height: 25%;
    }
    
    .titreC2-1 {
        font-size: 1.3rem;
        font-weight: bold;
        margin-top: 5%;
        margin-left: 10%;
        font-family: "DM Serif Display", sans-serif;
    }
    
    .descriptionC2-1 {
        font-size: 1.5rem;
        margin-left: 10%;
        font-family: "DM Serif Display", sans-serif;
    }
    
    .bloc2-2 {
        width: 100%;
        height: 75%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
    
    
    