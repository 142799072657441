
.containerContact {
    width: 100%;
    height: 100vh;
}


.containerContact-1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

/* PARTIE GAUCHE */

.ContactBlocGauche {
    width: 50%;
    height: 100%;
}

.ContactVide {
    width: 100%;
    height: 10vh;
}

.ContactBlocHaut {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ContactTitre1 {
    width: 85%;
    height: 40px;
    margin-bottom: 10px;
}

.ContactSousTitre1 {
    width: 85%;
    height: 20px;
}

.TitreHaut {
    font-size: 1.2rem;
    font-weight: bold;
}


.SsTitreHaut {
    font-size: 1em;
}

.ContactBlocFormulaire {
    width: 100%;
    height: 70%;
}

.ContactBlocMilieu {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
}

.ContactMilieuGauche {

    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.BMG {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

/* Style des boutons du formulaire */
.p-error{
    font-size: 12px;
    width: 60%;
    color: rgb(173, 173, 173);
}

.blocName{
    width: 100%;
    height: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 10px;
}

.BN {

    width: 60%;
    height: 70%;
    background-color: none;
    border-bottom: 2px solid rgb(0, 0, 0);
    border-top: none;
    border-right: none;
    border-left: none;

}

.ContactMilieuDroite {
    width: 50%;
    height: 100%;
}


.BMD {
    width: 100%;
    height: 100%;

}

.ContactBlocMessage {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.BMess {

    width: 100%;
    height: 80%;
    background-color: none;
    border-bottom: 2px solid black;
    border-top: none;
    border-right: none;
    border-left: none;

}

.ContactBlocBas {
    width: 100%;
    height: 10%;
}

.ContactBasBlocBouton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boutonEnvoyer {
    background-color: Black;
    color: White;
    width: 140px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s;
    font-weight: bold;
    font-size: 0.8rem;
    text-decoration: none;
    text-align: center;
    font-family: "DM Serif Display", sans-serif;
}

.boutonEnvoyer:hover{
    background-color: White;
    color: black;
    border: 1px solid black;
}


/* PARTIE DE DROITE */

.ContactBlocDroite {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactBlocCentraleDroite {
    width: 70%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactBackground {
    background-image: url('../assets/images/image-ville-du-luxembourg-centre-ville.webp');
    background-size: cover;
    background-position: center;
    width: 90%;
    height: 90%;
    border-radius: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ContactBandeauBackground {
    width: 60%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContactTitre {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    color: white;
}

.ContactDescription {
    font-size: 1rem;
    text-align: center;
    color: white;
}

.ContactBouton {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}



/*--------------------------------
            BOUTON
---------------------------------*/

.savoirplus {
    background-color: White;
    color: black;
    width: 140px;
    height: 50px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: color 0.5s, background-color 0.5s;
    font-size: 1rem;
    text-decoration: none;
    text-align: center;
    font-family: "DM Serif Display", sans-serif;
}

.savoirplus:hover {
    background-color: black;
    color: white;
    border: 1px solid black;
}



@media all and (max-width: 1000px) {

    .containerContact {
        width: 100%;
        height: 200vh;

    }

    .containerContact-1 {

        flex-direction: column;

    }

    .ContactTitre1,
    .ContactSousTitre1 {
        height: 30%;
    }


    .ContactBlocGauche {

        width: 100%;
        height: 100%;
    }


    .ContactBlocDroite {

        width: 100%;
        height: 100%;
    }

    .ContactBandeauBackground {
        width: 100%;
        height: 25%;

    }

    .ContactBlocMilieu {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .ContactMilieuGauche,
    .ContactMilieuDroite {

        width: 100%;
        height: 100%;
    }

    .p-error{
        width: 80%;
    }

    .blocName {
        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .BN {

        width: 80%;
        height: 70%;

    }

    .ContactBlocCentraleDroite{
        width: 500px;
    }

}


@media all and (max-width: 500px) {


    /*center le cadre */
    .BMD {
        display: flex;
        justify-content: center;
        align-items: center;

    }


    .BMess {
        text-align: center;

    }
}




@media (orientation:landscape) and (max-width: 1000px) {

    .containerContact {
        width: 100%;
        height: 200vh;

    }

    .containerContact-1 {

        flex-direction: column;

    }

    .ContactBlocGauche {

        width: 100%;
        height: 100%;
    }


    .TitreHaut,
    .SsTitreHaut {
        font-size: 1rem;
    }

    .ContactBlocMilieu {
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: row;
    }

    .boutonEnvoyer {
        background-color: rgb(0, 0, 0);
        color: rgb(255, 255, 255);
        width: 120px;
        height: 20px;
    }

    .ContactTitre {
        font-size: 1rem;

    }

    .ContactDescription {
        font-size: 0.8rem;
    }

}