.ArticleHeader{
    height: 540px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ArticleHeader__titleContainer{
    height: auto;
    width: 100%;
    text-align: left;

}

.ArticleHeader__imgContainer{
    height: 250px;
    width: 500px;
    text-align: left;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

#img{
    width: 100%;
    object-fit: cover;
}

.ArticleHeader__descriptionContainer{
    height: auto;
    width: 100%;
    text-align: left;
}

@media (max-width: 768px) {
    .ArticleHeader{
        align-items: center;
    }

    .ArticleHeader__titleContainer{
        text-align: left;
    }

    .ArticleHeader__descriptionContainer{
        text-align: left;
    }
}

@media (max-width: 520px) {
    .ArticleHeader__imgContainer{
        width: 100%;
    }

    .ArticleHeader{
        height: 640px;
    }
}