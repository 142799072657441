@mixin resizeHeight () {
    @media (min-width:425px) {
        height: 270px;
    }

    @media (min-width:587px) {
        height: 360px;
    }

    @media (min-width:766px) {
        height: 280px;
        width: calc(100% - 370px);
    }
    @media (min-width:857px){
        height: 340px;
    }
}

.container_Slider {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 15px;
    @include resizeHeight();
}

.imageContainer_Slider {
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
}

.img_Slider {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.btnContainerWrapper_Slider {
    height: 200px;
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include resizeHeight();
}

.btn {
    width: 40px;
    height: 40px;
    background-color: #fff;

    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}

.next {
    margin-right: 10px;
}

.prev {
    margin-left: 10px;
    transform: rotate(180deg);
}

.svgIcon_Slider {
    height: 100%;
    width: 100%;
}