
$primary-color: #007bff;
$secondary-color: #333;
$border-color: #ddd;
$background-light: #f0f0f0;
$active-color: green;

.progress-bar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(232, 232, 232, 0.788);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid $border-color;
  z-index: 4;

  .step {
    display: flex;
    align-items: center;
    gap: 5px;

    .icon {
      font-size: 18px;
    }

    .circle {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid #ccc;
      background: $background-light;
      cursor: pointer;

      &.active {
        background: $active-color;
        color: white;
        border-color: $active-color;
      }
    }

  }

  .button-primary {
    background: $primary-color;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }

  .button-secondary {
    background: $secondary-color;
    color: white;
    padding: 6px 12px;
    border: none;
    border-radius: 8px;
    margin-left: 10px;
    cursor: pointer;
  }
}
