.articleEnd{
    height: auto;
    width: 100%;
    margin-top: 30px;
}

.articleEnd__Title{
    height: auto;
    width: 100%;
    text-align: left;   
    margin-bottom: 20px;
}

.articleEnd__toSum{
    text-align: left;
    height: auto;
    width: 100%;
}