.containerAbout {
    height: 800px;
}

.containerAbout-1 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.BlocVideAbout {
    width: 100%;
    height: 10vh;
}

.BlocHautAbout {
    width: 100%;
    height: 10%;
}

.B-TitreAbout {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TitreAbout {
    font-size: 1.4em;
    font-weight: bold;
}

.SsTitreAbout {
    font-size: 1.2rem;
}

.BlocBasAbout {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
}

.BlocGaucheAbout {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.BlocImages {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.BlocImageHaut {
    width: 95%;
    height: 65%;
    display: flex;
    flex-direction: row;
}

.BlocInterneImageGauche {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blocImageABout1 {
    width: 95%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    object-position: center;
}



.blocImageABout2 {
    width: 95%;
    height: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image1H {
    width: 85%;
    height: 95%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}



.BlocInterneImageDroite {
    width: 50%;
    height: 100%;
}

.blocImageAbout3 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    object-position: center;
}

.imge1Droite {
    width: 80%;
    height: 99%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}

/*----------Partie basse du groupe d'images ----------*/

.BlocImageBas {
    width: 95%;
    height: 25%;
}

.blocImageAbout4 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.image1Bas {
    width: 90%;
    height: 95%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
}


/*------------ PARTIE A DROITE ------------*/
.BlocDroiteAbout {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.blocStructureDroite {
    width: 95%;
    height: 95%;
}

.bandeauAbout1,
.bandeauAbout3,
.bandeauAbout5 {
    width: 100%;
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bandeauAbout2,
.bandeauAbout4 {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.bandeauAbout6 {
    width: 100%;
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.titreBandeau {
    font-size: 1.1em;
    font-weight: bold;
}

.zoneTexteAbout {
    max-height: 90%;
    font-size: 1rem;
    overflow: auto;
}


@media all and (max-width:800px) {

    .containerAbout {
        height: auto;
    }


    .containerAbout-1 {

        height: 100%;
        display: flex;
        flex-direction: column;
    }


    .BlocVideAbout {
        height: 10vh;
    }

    .BlocHautAbout {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .TitreAbout {
        font-size: 1.2em;
        font-weight: bold;
    }

    .BlocBasAbout {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
    }


    .BlocImages {
        width: 75%;
        height: 100%;
    }


    .BlocGaucheAbout {
        width: 100%;
        height: 600px;
        margin-bottom: 1%;

    }

    .BlocDroiteAbout {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1%;
    }
}

@media all and (max-width:600px) {


    .containerAbout {
        height: auto;

    }


    .containerAbout-1 {

        height: 100%;
        display: flex;
        flex-direction: column;
    }


    .BlocVideAbout {
        height: 10vh;
    }

    .BlocHautAbout {
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .TitreAbout {
        font-size: 1.2em;
        font-weight: bold;
    }

    .BlocBasAbout {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
    }

    .BlocGaucheAbout {
        width: 100%;
        height: 400px;
        margin-bottom: 1%;

    }

    .BlocImages {
        width: 95%;
        height: 100%;
    }


    .BlocInterneImageDroite {
        width: 50%;
        height: 100%;

    }

    .blocImageAbout3 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .imge1Droite {
        width: 80%;
        height: 99%;
        border-radius: 10px;
    }


    .BlocDroiteAbout {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1%;
    }

}

@media (max-width: 477px) {
    .TitreAbout {
        font-size: 1.2em;
        font-weight: bold;
        margin-left: 20px;
        margin-right: 20px;
    }

}


/* PARTIE 2*/


.blocBasP2 {
    height: 500px;

}


/*---BAS--*/

.TitreP2AboutBas {
    height: auto;
    display: flex;
    align-items: center;
    padding-left: 8%;
    font-size: 1.1em;
}


.blocImageBasAboutP2 {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.encadreImageAbout {
    height: 80%;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.encadreInfoAbout {
    height: 80%;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.fondImageAbout {
    width: 80%;
    height: 90%;
    background-image: url('../assets/images/image-about-qui-sommes-nous-lac-marienthal.webp');
    background-size: cover;
    border-radius: 20px;

}

.bandeauHautBackground {
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    padding-left: 5%;
}

.bandeauHautBackground-1 {
    width: 40%;
    height: 60%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: bold;
}

.bandeauBasBackground {
    width: 100%;
    height: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 5%;
    padding-bottom: 5%;
}

.bandeauBasBackground-1 {
    width: 40%;
    height: 35%;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
}

.bandeauBasBackground-1:hover {
    background-color: black;
    color: white;
}

.encadreInfoAbout-1 {
    height: 80%;
    width: 90%;
    border: 2px solid #333;
    border-radius: 20px;
}

.TB2 {
    height: 25%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    font-size: 1.5rem;
    font-weight: bold;
}

.TB2-1 {
    height: 70%;
    display: flex;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 1.2rem;
    text-align: justify;
}

/*Reste à faire le responsive */
/* Passer le bloc du bas en column au lieu de row*/
/*verifier la police du bloc du haut qui devra se réduire */


@media all and (max-width: 1500px) {


    .bandeauHautBackground-1 {
        font-size: 1rem;
    }

    .TB2-1 {
        font-size: 1rem;
    }
}

@media all and (max-width: 1200px) {


    .bandeauHautBackground-1 {
        width: 60%;
    }

    .TB2-1 {
        padding-top: 25%;
        font-size: 1rem;
        overflow: auto;
    }
}


@media all and (max-width: 850px) {

    .BlocDroiteAbout {
        height: 900px;

    }

    .blocStructureDroite {
        width: 85%;
    }


    .TitreP2About {
        height: 30%;
        display: flex;
        align-items: center;
        padding-left: 8%;
        font-size: 1.2rem;
    }

    .DescP2About {
        font-size: 1rem;
    }

    .blocBasP2 {
        height: 600px;
    }

    .TitreP2AboutBas {
        margin-top: 2%;
    }

    .fondImageAbout {
        height: 300px;
    }


    .encadreInfoAbout-1 {
        height: 350px;

    }

    .bandeauHautBackground-1 {
        width: 80%;
    }

}

@media all and (max-width: 550px) {


    .blocBasP2 {
        height: auto;
    }

    .blocImageBasAboutP2 {
        display: flex;
        flex-direction: column;
        margin-top: 5%;
    }

    .encadreImageAbout {
        width: 90%;
    }

    .encadreInfoAbout {
        width: 90%;
        margin-top: 5%;
    }

    .encadreInfoAbout-1 {
        height: 400px;
    }

    .TB2-1 {
        padding-top: 0%;
        font-size: 1rem;
        overflow: none;
    }
}


@media all and (max-width: 350px) {


    .bandeauBasBackground-1 {
        width: 90%;
    }

    .TB2 {
        padding-left: 0%;
        justify-content: center;
    }

    .bandeauHautBackground-1 {
        width: 95%;
        font-size: 0.9rem;
    }

    .encadreInfoAbout-1 {
        height: 500px;
        margin-bottom: 5%;
    }

    .TB2-1 {
        padding-top: 0%;
        font-size: 1rem;
        overflow: none;
    }
}



@media (orientation:landscape) and (max-width: 1500px) {



    .containerAbout {
        height: 200vh;
    }
}