@import "../components_style/utils";

@mixin textLine ($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $line;
    -webkit-line-clamp: $line;
    /* Limite à 2 lignes */
    overflow: hidden;
}

.connexionPage {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
}


.title_headSection {
    width: 100%;
    height: auto;
    padding: 10px 10px 10px 10px;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }
}

.roomList {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #ededed;
    padding-top: 5px;
    padding-bottom: 5px;

    &__container {
        width: calc(100% - 20px);
        height: auto;
        display: flex;
        flex-wrap: wrap;

        @media (min-width :1008px) {
            width: 1008px;
        }
    }
}

.suppList {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    //background-color: #EFEFEF;
    background-color: #ff9f9f;
    padding-bottom: 15px;
}

.addCarteCadeauSection {
    height: auto;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }

    &_priceContainer {
        height: 80px;
        width: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_price {
            font-size: 29px;
            width: fit-content;
            height: fit-content;
            color: green;
            font-weight: bold;
        }

        &_priceDescription {
            text-align: center;
            font-size: 12px;
        }
    }

    &_btnWrapper {
        height: 45px;
        width: calc(100% - 90px);
        margin-left: 10px;
        background-color: #000000;
        color: white;
        border-radius: 6px;
        text-align: center;
    }
}

.btnContainerEndOfPage {
    width: 100%;
    height: fit-content;
    padding: 0px 10px 10px 10px;

    @media (min-width :1008px) {
        /// SCREEN RESPONSIVE ------------ ///////////////
        width: 1008px;
    }
}

#termOfSell_text {
    text-decoration: underline;
}

.app-container {
    padding: 10px;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    //background-color: #ffa3a3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media (min-width: 1008px) {
        max-width: calc(100% - 20px);
    }

    .description {
        margin-bottom: 20px;
    }

    .submit-btn {
        background-color: black;
        color: white;
        margin-top: 20px;
    }
}

.main {
    display: flex;
    max-width: 1008px;
    //background-color: #52ff89;

    @include blocInitialiser(100%, 100%, none);

    @media (max-width:735px) {
        flex-direction: column;
        align-items: center;
    }

    &_inputForm {
        @include blocInitialiser(100%, 50%, none);
        margin-right: 15px;
        padding-right: 15px;

        @media (max-width:735px) {
            margin: 0px;
            padding: 0px;
            width: 100%;
        }
    }

    &_itemList {
        @include blocInitialiser(100%, 50%, none);

        &_itemSlide {
            @include blocInitialiser(430px, 100%, none);
            overflow-y: scroll;
        }

        @media (max-width:735px) {
            margin-top: 15px;
            width: 100%;
        }
    }
}
