.thankYouPage_pageView {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 60px);

    &__elmntContainer {
        width: 80vw;
        @media (max-width:480px) {
            width: calc(100% - 60px);
        }
    }

    &__socialContainer {
        margin-top: 20px;
        display: flex;
        justify-content: left;
        align-items: center;

        @media (max-width:480px) {
            flex-direction: column;
        }

        &__textContainer {
            width: fit-content;
        }

        &__instaIconContainer {
            width: 30px;
            height: 30px;
            margin-left: 15px;

            &__icon {
                width: 100%;
                height: 100%;
                transition: width 0.2s ease-out;
            }

            @media (max-width:480px) {
                margin-top: 30px;
            }
        }

        &__instaIconContainer :hover {
            width: 40px;
        }
    }
}