
.shoppingList {
    height: 28px;
    width: auto;
    display: flex;
    background-color: black;
    border-radius: 5px;
    z-index: 2;
    position: fixed;
    top: 25px;
    right: 15px;

    &_textBox {
        width: auto;
        height: 100%;
        color: white;
        font-weight: 600;
        font-size: 13px;
        display: flex;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
    }

    &_svgIcon{
        height: 70%;
        width: 20px;
    }

    &_priceBox {
        width: auto;
        height: 100%;
        padding-left: 8px;
        padding-right: 5px;
        color: white;
        border-left: 2px solid white;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 600;
    }

    .itemCountBubble {
        position: absolute; // Positionnez la bulle par rapport à la div parente
        top: -10px; // Ajustez pour placer la bulle au-dessus de la div
        right: -10px; // Ajustez pour placer la bulle à droite de la div
        height: 20px;
        width: 20px;
        background-color: rgba(128, 15, 47, 0.867);
        border-radius: 50%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0px;
        font-size: 12px;
        font-weight: bold;
        z-index: 2;
    }
}

h3{
    padding: 0px;
    margin: 0px;
}

.containerShoppingCart {
    background-color: #ffffff;
    max-width: calc(100% - 30px);
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 2; // ------------ FAUT METTRE SUR 1 / CEST POUR LE TEST-------------
    position: fixed;
    top: (35px + 28px + 10px);
    border-radius: 15px;
    right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_margin{
        margin: 10px;
    }
    &_titleAndText{
        width: fit-content;
        height: auto;
    }
    &_date-range{
        margin-top: 10px;
        &_spanLeft{
            margin-right: 15px;
        }
    }
    &_total{
        height: auto;
        width: 100%;
        margin-top: 10px;
        display: flex;
        &_amount{
            color: #00630A;
            margin-left: 15px;
            font-weight: 900;
        }
    }
}