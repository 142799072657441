@import '../components_style/_utils.scss';

.descriptionContainer{
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


//-----------------------
//-----------------------
//     Box de voyage 
//-----------------------
//-----------------------

.sectionBoxDeVoyage{
    @include blocInitialiser(auto,100%,none);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
    margin-top: 70px;
    &__PageViewContainer{
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-left: 20px;
        margin-right: 20px;
    }
    
}
