a{
    color: black;
}

.ArticlePage{
    height: auto;
    width: 100%;
}

#img{
    width: 100%; 
    height: 100%; 
    object-fit: cover;
}

.ArticlePage__imgContainer{
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.ArticlePage__titleContainer{
    position: absolute;
    height: 300px;
    width: 1024px;
}

.ArticlePage__titleContainer_title{
    height: 100px;
    width: auto;
    display: flex;
    align-items: center;
    color: white;
    text-align: start;
    margin-left: 10px;
    margin-right: 10px;
    color: black;
}

.ArticlePage__titleContainer_text{
    height: 50%;
    width: 400px;
    position: absolute;
    color: rgb(0, 0, 0);
    text-align: start;
    margin-left: 10px;
    margin-right: 10px;
}

/*---------------------*/

.ArticlePage__subTitle{
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ArticlePage__subTitle_titleContainer{
    height: 100%;
    width: 1024px;
    margin: 0px 10px 0px 10px;
    display: flex;
    align-items: center;
}

.ArticlePage__articleSection{
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.ArticlePage__articleSection__articleContainer{
    height: 100%;
    width: 1034px;
    display: flex;
    flex-wrap: wrap;
}

.ArticlePage__articleSection__article{
    height: 160px;
    width: 400px;
    background-color: #ffffff;
    margin: 5px;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-out;
}

.ArticlePage__articleSection__article:hover{
    box-shadow: 5px 5px 24px -11px rgba(0,0,0,0.76);
}

.ArticlePage__articleSection__article__imgContainer, .ArticlePage__articleSection__article__textAndButtonContainer{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;    
}

.imgContainer{
    height: 65px;
    width: 120px;
    margin-left: 7px;
    overflow: hidden;
    border-radius: 5px;
}

.articleContainer{
    height: 100%;
    width: auto;
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
}

#img2{
    width: 100%;
}

.ArticlePage__articleSection__article__textAndButtonContainer{
    justify-content: center;
    text-align: left;

}

.ArticlePage__articleSection__article__text{
    height: 70px;
    width: 100%;
    margin: 0px 5px 5px 5px;
    font-size: 14px;
}

/*------------------------------
//
//   Responsive !
//
-------------------------------*/

@media (max-width: 1024px) {
    .ArticlePage__titleContainer{
        position: absolute;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ArticlePage__articleSection__article{
        height: 160px;
        width: auto;
    }
}

@media (max-width: 468px) {
    .ArticlePage__titleContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .ArticlePage__titleContainer_text{
        position: relative;
    }

    .ArticlePage__titleContainer_title{
        height: auto;
        text-align: center;
    }

    .ArticlePage__titleContainer_text{
        width: auto;
        text-align: center;
    }
}

@media (max-width: 425px) {
    h1{
        font-size: 25px;
    }
}