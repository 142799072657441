.CTA{
    height: 200px;
    width: 100%;
    background-color: rgb(0, 0, 0);
    margin-top: 30px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 10px;
}

#p-margin{
    margin-left: 20px;
}

.CTA__Title{
    height: auto;
    width: auto;
    text-align: left;
    margin: 0px 10px 0px 10px;
}

.CTA__propositionValuer{
    height: auto;
    width: auto;
    text-align: left;
    margin: 0px 10px 0px 10px;
}

.CTA__creationUrgence{
    height: auto;
    width: auto;
    text-align: left;
    margin: 0px 10px 0px 10px;
}

.CTA__imgAndButtonContainer{
    height: auto;
    width: 100%;
    display: flex;
}

.CTA__ButtonAndAction{
    height: auto;
    width: 100%;
    align-items: center;
    display: flex;
    margin: 0px 10px 0px 10px;
}

.buttontemplate{
    height: 40px;
    width: 150px;
    background-color: #fff;
    border-radius: 10px;
    margin-right: 30px;
}

@media (max-width:739px) {
    .CTA__ButtonAndAction{
        width: auto;
        font-size: 13px;
    }
    .CTA{
        height: 250px;
    }
}

@media (max-width:500px) {
    .CTA{
        height: 220px;
    }

    .CTA__propositionValuer{
        display: none;
    }

    .CTA__imgAndButtonContainer{
        flex-direction: column;
    }

    .CTA__Title{
        font-size: 14px;
    }

    .CTA__ButtonAndAction{
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }

    #p-margin{
        margin-left: 0px;
    }
}

@media (max-width:373px) {
    .CTA{
        height: 250px;
    }
}
