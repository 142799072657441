#emailIcon{
    height: 35px;
}

.alert{
    padding: 5px;
    position: fixed;
    z-index: 3;    
    right: 20px;
    height: 50px;
    width: 200px;
    border-radius: 11px;
    background-color: #000000;
    display: flex;
    align-items: center;
    transform: translateY(-100px);
    animation: slide-in 5s ease-in-out;
    &_P{
        color: white;
        font-family: 'DM Serif Display', serif;
        margin-left: 5px;
        font-size: 14px;
    }
}

@keyframes slide-in {
    0% {
        transform: translateY(-100px);
    }
    20%{
        transform: translateY(0px);
        top: 30px;
    }
    100% {
        transform: translateY(-200px);
    }
}