.article{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}

.article__container{
    width: 1024px;
    height: auto;
    margin: 0px 10px 0px 10px;
}

.btn-black{
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 10px 0px 10px 0px;
    width: auto;
    height: auto;
    background-color: #000000;
}

.link{
    margin: 0px;
    padding: 0px;
}

.img{
    width: 400px;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.imgCTA{
    width: 400px;
    height: 300px;
    overflow: hidden;
    object-fit: contain;
    object-position: center;
    background-color: #a8a8a8;
    border-radius: 10px;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
}

.btnImgCta{
    position: absolute;
}

.btnImgCta{
    width: 400px;
    height: 40px;
    margin: 0px 0px 0px 0px;
}

@media (max-width:432px) {
    .img{
        height: 100%;
        width: 100%;
    }
    .btnImgCta{
        width: 90%;
        height: 70px;
    }
    .imgCTA{
        width: 100%;
    }
}