.container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    border-radius: 15px;
}

@media (min-Width:715px) {
    .container {
        width: 100%;
        height: 270px;
        overflow: hidden;
        border-radius: 15px;
    }
}

.imageContainer {
    height: 100%;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
}

.img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.btnContainerWrapper {
    height: 200px;
    z-index: 1;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-Width:715px) {
    .btnContainerWrapper {
        height: 270px;
    }
}

.btn {
    width: 40px;
    height: 40px;
    background-color: #fff;

    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bolder;
    cursor: pointer;
}

.next {
    margin-right: 10px;
}

.prev {
    margin-left: 10px;
    transform: rotate(180deg);
}

.svgIcon {
    height: 100%;
    width: 100%;
}