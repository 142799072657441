.navbarTravelPlanner {
    //background-color: #ff4646;
    margin-top: 25px;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 0px;
    width: 100%;
    &__container {
        display: flex;
        width: auto;
        height: auto;
        background-color: rgba(245, 245, 245, 0.683);
        padding: 10px 10px 10px 0px;
        border-radius: 15px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        @media (max-width:1074px) {
            flex-direction: column;
            gap: 10px;
            width: 50%;
        }

        @media (max-width:475px) {
            width: 100%;
        }

        &__dateInputs,
        &__finalInputs {
            display: flex;
            gap: 10px;
            margin-left: 10px;
        }

        &__dateInputs {
            background-color: #71a5ff;
        }

        &__finalInputs {
            background-color: #cb71ff;
        }
    }
}
