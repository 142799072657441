@mixin blocInitialiser($h,$w,$bgc: #c7e9ff) {
    height: $h;
    width: $w;
    background-color: $bgc;
}

@mixin flexStyle($itemState:center,$contentState:center){
    display: flex;
    align-items: $itemState;
    justify-content: $contentState;
}

@mixin textLine ($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $line;
    -webkit-line-clamp: $line;
    /* Limite à 2 lignes */
    overflow: hidden;
}