
.footer {
    margin-top: 30px;
    width: 100%;
    height: 200px;
    margin-bottom: 30px;
}

.blocFooter {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.blocHaut {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
}

.logoFooter {
    font-size: 2rem;
    color: black;
}

.blocMilieu {
    width: 700px;
    height: 30%;
}

.blocF-footer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.menuFooter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 30%;
    text-transform: uppercase;
}


.liFooter {
    text-decoration: none;
    list-style: none;
    color: black;
}

.navLink{
    text-decoration: none;
    font-family: 'DM Serif Display', serif;
}

.navLink:hover{
    text-decoration: underline;
}

.aFooter {
    text-decoration: none;
    list-style: none;
    color: black;
    font-size: 1rem;
}

.blocBas {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
}

.logoInsta,
.logoFb {

    font-size: 2.5rem;
    margin-left: 2%;
    margin-right: 2%;
    cursor: pointer;
}


/* ----------------------------------------------------
            RESPONSIVE FOOTER- 600px et 1000px
---------------------------------------------------*/



@media all and (max-width: 729px) {

    .footer {
        width: 100%;
        height: auto;
    }

    .blocHaut {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .logoFooter {
        font-size: 2rem;
        color: black;
    }

    .blocMilieu {
        background-color: #fff;
        display: none;
    }

    ul.menuFooter {
        display: none;
    }

    .blocBas {

        width: 100%;
        height: 30%;
        display: flex;
        justify-content: center;
    }

    .logoInsta {
        font-size: 2.5rem;
        margin-left: 2%;
        margin-right: 2%;
    }

    .logoFb {

        font-size: 2.5rem;
        margin-left: 2%;
        margin-right: 2%;
    }

}