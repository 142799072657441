
.ArticlePage__articleSection__article{
    height: 160px;
    width: 400px;
    background-color: #ffffff;
    margin: 5px;
    border-radius: 10px;
    transition: box-shadow 0.3s ease-out;
}

.ArticlePage__articleSection__article:hover{
    box-shadow: 5px 5px 24px -11px rgba(0,0,0,0.76);
}

.ArticlePage__articleSection__article__imgContainer, .ArticlePage__articleSection__article__textAndButtonContainer{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;    
}

.imgContainer{
    height: 65px;
    width: 120px;
    margin-left: 7px;
    overflow: hidden;
    border-radius: 5px;
}

.articleContainer{
    height: 100%;
    width: auto;
    text-align: left;
    padding-left: 5px;
    padding-top: 5px;
}

#img2{
    width: 100%;
}

.ArticlePage__articleSection__article__textAndButtonContainer{
    justify-content: center;
    text-align: left;

}

.ArticlePage__articleSection__article__text{
    height: 70px;
    width: 100%;
    margin: 0px 5px 5px 5px;
    font-size: 14px;
}

@media (max-width: 768px) {
    .ArticlePage__articleSection__article{
        height: 160px;
        width: auto;
    }
}

@media (max-width: 399px) {
    .ArticlePage__articleSection__article{
        height: 180px;
        width: auto;
    }
}