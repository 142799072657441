@import '../components_style/_utils.scss';

@mixin textLine ($line) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    line-clamp: $line;
    -webkit-line-clamp: $line;
    /* Limite à 2 lignes */
    overflow: hidden;
}


.badge {
    top: 0px;
    right: 0px;
    padding: 5px 10px;
    position: fixed;
    z-index: 1;
    background: linear-gradient(to right, #000000, #d4145a);
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    height: fit-content;
}

.travel-box {
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 320px;
    cursor: pointer;
    margin: 7.5px;
    @media screen and (max-width: 348px) {
        width: 290px;
    }
      
    &__image {
        width: 100%;
        height: 192px;
        object-fit: cover;
        transition: transform 0.3s ease;
    }

    &__image:hover {
        transform: scale(1.10);
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
    }

    &__titleContainer {
        @include textLine(1);
    }

    &__title {
        font-size: 1.125rem;
        font-weight: 600;
    }

    &__price {
        color: #4b5563;
    }

    &__button {
        background: #e5e7eb;
        color: black;
        border-style: none;
        padding: 8px 16px;
        border-radius: 8px;
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;

        &:hover {
            background: #d1d5db;
        }
    }
}