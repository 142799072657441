.conditions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.main-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
}

.section {
    margin-bottom: 20px;
    max-width: 600px;
}

.section-title {
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.section-paragraph {
    font-size: 1rem;
    line-height: 1.5;
}