.activité__numberOfPeople{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    padding-bottom: 0px;
    padding-left: 0px;
    background-color: white;
    margin-left: 10px;
}

.activité__numberOfPeople__signContainer{
    height: 30px;
    width: 30px;
    background-color: #ebebeb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border-style: none;
    &_sign{
        font-size: 20px;
    }
}
