.pageView{
    height: auto;
    width: 100dvw;
}

.pageContentContainer{
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
    height: auto;
}

.titleContainer{
    margin-top: 50px;
}

.imageSection{
    margin-top: 20px;
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.imageContainer{
    height: 200px;
    width: 300px;
    margin: 5px;
    display: flex;
    justify-content: center;
    background-color: #b4b4b4;
    overflow-clip-margin: content-box;
    overflow: clip;
    border-radius: 12px;
}

.img{
    width: 100%;
    height: 100%;
}

@media (max-width:758px) {
    .imageContainer{
        height: 150px;
        width: 250px;
    }
}

@media (max-width:563px) {
    .imageContainer{
        height: 100px;
        width: 150px;
    }
}

.textSection, .buttonSpacing{
    margin-top: 10px;
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}

.explainationText{
    margin-top: 10px;
    margin-bottom: 25px;
}

#buttonPageVente{
    height: fit-content;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonSpacing{
    display: flex;
    align-items: center;
    justify-content: center;
}