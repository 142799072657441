@import '../components_style/_utils.scss';
.advantageContainer {
    margin-top: 10px;
    height: auto;
    width: 100%;
    visibility: visible;
    overflow: hidden;
    display: block;

    &_topSetting {
        display: flex;
        flex-wrap: wrap;
    }

    &_elmnt {
        @include textLine(1);
        height: 23px;
        width: fit-content;
        padding-left: 3px;
        padding-right: 3px;
        padding-bottom: 4px;
        margin: 0px 5px 5px 0px;
        border-radius: 7px;
        border: 1px solid rgb(196, 196, 196);
        background-color: #fff;
    }
}